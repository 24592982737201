import { createStore } from 'vuex';
import { auth } from "./auth.store";
import { exercises } from './exercises.store';
import { permission } from "./permission.store";
import { app } from '@/store/app.store';
import { information } from '@/store/information.store';

export const store = createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app,
    auth,
    exercises,
    permission,
    information,
  },
})
