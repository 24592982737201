module.exports = {
  close: "Close",
  send: "Send",
  respond: "Reply",
  selectLanguage: "Select language",
  cancel: "Cancel",
  apply: "OK",
  update: "Update",
  date: "Date",
  startTime: "Start time",
  endTime: "End time",
  back: "Back",
  edit: "edit",
  recipient: "recipient",
  delete: "Delete",
  description: "Description",
  save: "Save",
  saveAndNew: "Save and new",
  at: "om",
  from: "from",
  yes: "Yes",
  no: "No",
  and: "and",
  areYouSure: "Are you sure?",
  addFiles: "Add files",
  error: {
    somethingWentWrong: "Something went wrong. Please try again later."
  },
  greetings: {
    morning: "Good morning",
    afternoon: "Good afternoon",
    evening: "Good evening"
  },
  auth: {
    loading: "Loading...",
    description: "Login to use Telerevalidatie.",
    version: "Version: v{version}",
    header: "Login",
    email: "Email address or phone",
    password: "Password",
    login: "Login",
    failed: "Failed",
    failedText: "Incorrect credentials",
    forgotPasswordQuestion: "Forgot your password?",
    forgotHeader: "Forgot password",
    forgotDescription: "Enter your email address to reset your password",
    forgotSuccessText: "A password reset email has been sent to you",
    reset: "Reset",
    twoFactorHeader: "2-step verification",
    twoFactorDescription: "Enter your authenticator code below.",
    code: "Code",
    ssoLogin: "Login with SSO"
  },
  dashboard: {
    information: "Information",
    dashboard: "Dashboard",
    health: "Steps",
    exercises: "Exercises",
    notes: "Note",
    team: "Treat team",
    settings: "Settings",
    logout: "Logout",
    timetable: "Time division",
    messages: "Messages",
    questionnaires: "Questionnaires",
    goals: "Goals",
    activities: "Activities",
    patients: "Patients",
    children: "Children"
  },
  team: {
    title: "Team",
    sendMessage: "Send message"
  },
  settings: {
    title: "Settings",
    general: "General",
    selectLocale: "Language",
    selectFontSize: "Text size",
    health: "Steps",
    fontSizes: {
      small: "small",
      medium: "Average",
      large: "Large",
      xl: "Extra large",
      xxl: "Very large",
    },
    changeTheme: "Change theme",
    themes: {
      default: "Default",
      kids: "Children",
      construction: "Construction",
      space: "Space",
      craft: "Design",
      plant: "Gardening"
    },
    notifications: "Notifications",
    homeworkNotification: "Homework reminders",
    notificationBadge: "Show badges on home screen",
    myAccount: "My account",
    security: "Password and security",
    logout: "Logout",
    about: "About",
    privacy: "Privacy policy",
    bio: "Enable FaceID/TouchID",
  },
  security: {
    description: "Change your password here",
    passwordOld: "Current password",
    passwordNew: "New password",
    passwordConfirmation: "Repeat new password",
    changePassword: "Change password",
    passwordUpdated: "Your password has been successfully changed"
  },
  lang: {
    dutch: "Dutch",
    english: "English"
  },
  exercises: {
    title: "Exercises",
    complete: "Exercise completed",
    amountToDo: "{current} of the {max} done",
    toDo: "To do",
    none: "There are no exercises available for the selected day",
    markAsDone: "Done",
    markForSkip: "Skip"
  },
  exercise: {
    instructions: "Instructions",
    addNote: "Add comment",
    notePlaceholder: "Add your note here",
    modalStatusTextcomplete:
      "When you click send you mark the exercise as done with the note attached.",
    modalStatusTextskip:
      "When you click send you mark the exercise as skipped with the note attached.",
    statusChanged: "The status of this exercise has been updated",
    videoProcessing: "This exercise includes a video. The video is currently still being processed.",
  },
  notes: {
    title: "Notebooks",
    noNotes: "There are no notebooks",
    note: "Note",
    newMessage: "New message",
    message: "Message",
    private: "Private",
    other: "Other notebooks",
    notifyUsers: "Sent notification to",
    everyone: "Everyone",
    showMessages: "Show messages of",
  },
  information: {
    noItems: "There are no articles",
    title: "Information",
    complete: "Finished",
    answer: "Answer",
    answerBtn: "Enter an answer",
    answerPlaceholder: "Add your answer here",
    answerSubmitted: "Your answer has been sent",
    files: "Files",
    markAsUnread: "Mark as unread",
    unreadMessage: "This item has been marked as unread",
    markAsFavorite: "Item has been marked as favorite",
    markAsUnFavorite: "Item has been unmarked as favorite",
    toggleFavorite: "Mark as favorite",
    untoggleFavorite: "Remove as favorite",
    extraPages: "Table of contents",
    videoProcessing: "This information item comes with a video. The video is currently still being processed.",
  },
  messages: {
    title: "Messages",
    noMessages: "There are no messages",
    newMessage: "New message",
    subject: "Subject",
    message: "Message",
    sent: "Sent",
    received: "Received",
    lastMessage: "Last message",
    validation: {
      recipients: "Select a recipient."
    },
    newResponse: "Response",
    selectRecipients: "Select recipients",
    recipients: {
      private: "Private",
      recipients: "Recipients",
      apply: "OK",
      cancel: "Close",
      notify: "Notify"
    },
    other: "Other messages",
    willSentTo: "This message will be sent to: {users}",
    videoProcessing: "The attached video is currently being processed. One moment please. You can view and download it later.",
  },
  goals: {
    title: "Goals",
    targetDate: "Round before:",
    completedAt: "Completed to:",
    newGoal: "New Goal",
    what: "What is your goal?",
    when: "When do you want to achieve this?",
    how: "How do you want to achieve this?",
    complete: "Finish goal",
    goalCreated: "New goal has been created",
    goalCompleted: "Goal completed",
    editGoal: "Edit Goal",
    goalUpdated: "Goal has been updated"
  },
  schedule: {
    title: "Time division",
    newActivity: "Activity",
    editActivity: "Edit activity",
    manageActivities: "Manage Activities",
    timeSettings: "Time settings",
    helpText: 'Press and hold a time longer in the calendar to create a new activity.',
  },
  activities: {
    noActivities: "Add a new activity to get started",
    activities: "Activities",
    activity: "Activity",
    minutes: "minutes",
    perHour: "per hour",
    new: "New",
    edit: "edit",
    category: "Category",
    selectActivity: "Select"
  },
  validation: {
    description: "Description required"
  },
  tests: {
    title: "Questionnaires",
    open: "Open",
    finished: "Finished",
    next: "Next question",
    prev: "Previous question",
    previewExisting: "You are viewing a completed questionnaire",
    sendNew: "Send questionnaire",
    typeHere: "Enter your answer here",
    selectDate: "Select a date",
    selectDropdown: "Select an option",
    validation: {
      required: "This question is required",
      min: "The answer must be at least {number}"
    },
    invalid: "Not all questions have been filled in correctly",
    submitted: "Your completed answers have been sent"
  },
  patients: {
    title: "Patients",
    nothingFound: "No results found.",
    otherPatients: "Other patients",
    exercises: "Exercises",
    notes: "Notes",
    addNote: "Add a new note",
    noExercises: "No exercises have been added",
    addExercise: "Add exercise",
    exerciseName: "Name of exercise",
    exerciseDescription: "Description",
    sessionsPerDay: "Sessions per day",
    sessions: "1 session | {count} sessions",
    sets: "Sets",
    noIndication: "No indication",
    setsOption: "1 set | {count} sets",
    repetitions: "Repetitions",
    repetitionsOption: "1 repetition | {count} repetitions",
    time: "Time",
    secondsOption: "{count} seconds",
    minutesOption: "1 minute | {count} minutes",
    planDirect: "Schedule immediately",
    exerciseCreated: "Exercise has been created",
    editExercise: "Edit exercise",
    exerciseUpdated: "Exercise has been updated",
    deleteExercise: "Delete exercise",
    deleteExerciseDescription: "Are you sure you want to delete this exercise?",
    exerciseDeleted: "The exercise has been deleted"
  },
  themes: {
    default: {
      labels: {
        welcomeText: "Telerevalidatie",
        welcomeTextDescription: "Nice to have you back",
        contentItemsText: "Information",
        contentItemsTextDescription:
          "Learn more about your treatment and how to deal with it",
        exercisesText: "Exercises",
        exercisesTextDescription:
          "Getting started with exercises and assignments",
        messagesText: "Messages",
        messagesTextDescription: "To your practitioner",
        notepadText: "Notebook",
        notepadTextDescription:
          "Send messages, photos and videos to all your practitioners",
        questionnairesText: "Questionnaires",
        questionnairesTextDescription:
          "Answer questions about how you are doing"
      }
    },
    construction: {
      labels: {
        welcomeText: "Hello builder {name}",
        welcomeTextDescription: "Nice to have you back",
        contentItemsText: "Read",
        contentItemsTextDescription:
          "Learn more about your treatment and how to deal with it",
        exercisesText: "Do",
        exercisesTextDescription:
          "Getting started with exercises and assignments",
        messagesText: "Messages",
        messagesTextDescription: "To your practitioner",
        notepadText: "Notebook",
        notepadTextDescription:
          "Send messages, photos and videos to all your practitioners",
        questionnairesText: "Questionnaires",
        questionnairesTextDescription:
          "Answer questions about how you are doing"
      }
    },
    space: {
      labels: {
        welcomeText: "Hello austronaut {name}",
        welcomeTextDescription: "Nice to have you back",
        contentItemsText: "Read",
        contentItemsTextDescription:
          "Learn more about your treatment and how to deal with it",
        exercisesText: "Do",
        exercisesTextDescription:
          "Getting started with exercises and assignments",
        messagesText: "Messages",
        messagesTextDescription: "To your practitioner",
        notepadText: "Notebook",
        notepadTextDescription:
          "Send messages, photos and videos to all your practitioners",
        questionnairesText: "Questionnaires",
        questionnairesTextDescription:
          "Answer questions about how you are doing"
      }
    },
    craft: {
      labels: {
        welcomeText: "Hello designer {name}",
        welcomeTextDescription: "Nice to have you back",
        contentItemsText: "Read",
        contentItemsTextDescription:
          "Learn more about your treatment and how to deal with it",
        exercisesText: "Do",
        exercisesTextDescription:
          "Getting started with exercises and assignments",
        messagesText: "Messages",
        messagesTextDescription: "To your practitioner",
        notepadText: "Notebook",
        notepadTextDescription:
          "Send messages, photos and videos to all your practitioners",
        questionnairesText: "Questionnaires",
        questionnairesTextDescription:
          "Answer questions about how you are doing"
      }
    },
    plant: {
      labels: {
        welcomeText: "Hello {name}, with the green fingers",
        welcomeTextDescription: "Nice to have you back",
        contentItemsText: "Read",
        contentItemsTextDescription:
          "Learn more about your treatment and how to deal with it",
        exercisesText: "Do",
        exercisesTextDescription:
          "Getting started with exercises and assignments",
        messagesText: "Messages",
        messagesTextDescription: "To your practitioner",
        notepadText: "Notebook",
        notepadTextDescription:
          "Send messages, photos and videos to all your practitioners",
        questionnairesText: "Questionnaires",
        questionnairesTextDescription:
          "Answer questions about how you are doing"
      }
    }
  },
  children: {
    title: "Children",
    description:
      "Here you see an overview of all your children who use the app. You can log in as one your child.",
    login: "Login this account",
    loginSuccess: "You are logged in to the account",
    switchBack: "Go back to your account",
    switchBackSuccess: "You have switched back to your account"
  },
  noRecipients: "No recipients selected.",
  health: {
    noSteps: "No data for your steps taken.",
    stepsTaken: "steps taken",
    history: "Steps",
    steps: "steps"
  },
  upload: {
    more: {
      title: 'How do you want to add a file?',
      camera: 'Use camera',
      file: 'Select a file',
      cancel: 'Cancel',
      video: 'Record a video',
    },
  },
  legend: 'Legend',
  descriptionRequired: 'Description is required',
  media: {
    title: "Media",
    new: "Add new media"
  },
};
