import ApiService from '@/services/api.service';
const CACHE_KEY = 'information_items';

const state = {
    items: JSON.parse(localStorage.getItem(CACHE_KEY) ?? '[]'),
    categoriesToHide: [],
};

const getters = {
    items(state: { items: [] }) {
        return  state.items;
    },

    categoriesToHide(state: { categoriesToHide: string }) {
        return state.categoriesToHide;
    }
};

const actions = {
    async fetchItems(context: any, forced = false) {
        if (context.state.items.length > 0 && ! forced) {
            return;
        }

        const { data } = await ApiService.get('/tasks/content-item')
        const filteredData: { category: any; items: any[] }[] = [];

        data.forEach((item: { category: any }) => {
            const exists = filteredData.find(f => f.category === item.category);

            if (exists) {
                exists.items.push(item);
            } else {
                filteredData.push({
                    category: item.category,
                    items: [
                        item,
                    ],
                });
            }
        });

        localStorage.setItem(CACHE_KEY, JSON.stringify(filteredData));
        context.commit('setItems', filteredData);
    },
    clearItems(context: { commit: (arg0: string, arg1: never[]) => void }) {
        localStorage.removeItem(CACHE_KEY);
        context.commit('setItems', []);
    },

    toggleCategory(context: any, category: string) {
        let newCategories = context.state.categoriesToHide;

        if (newCategories.includes(category)) {
            newCategories = newCategories.filter((cat: string) => cat !== category);
        } else {
            newCategories.push(category);
        }

        context.commit('setCategoriesToHide', newCategories);
    }
};

const mutations = {
    setItems(state: any, data: []) {
        state.items = data;
    },

    setCategoriesToHide(state: any, categories: string[]) {
        state.categoriesToHide = categories;
    }
}

export const information = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
