const TOKEN_KEY = "access_token";
const SECOND_TOKEN_KEY = "access_token_main";
const REFRESH_TOKEN_KEY = "refresh_token";

const TokenService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },

  saveToken(accessToken: string) {
    localStorage.setItem(TOKEN_KEY, accessToken);
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  },

  saveRefreshToken(refreshToken: string) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  },

  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  },

  switchAccount(accessToken: string) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    localStorage.setItem(SECOND_TOKEN_KEY, <string>localStorage.getItem(TOKEN_KEY));
    localStorage.setItem(TOKEN_KEY, accessToken);
  },

  hasMainAccount(): boolean {
    return localStorage.getItem(SECOND_TOKEN_KEY) !== null;
  },

  switchBack(): string {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const token = <string>localStorage.getItem(SECOND_TOKEN_KEY)
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.removeItem(SECOND_TOKEN_KEY);

    return token;
  }
}

export { TokenService };
