import moment from 'moment';

const state = {
    selectedDate: null,
};

const getters = {
    getSelectedDate(state: any) {
        const date = moment(state.selectedDate || undefined);
        return date.format('YYYY-MM-DD');
    },
};

const actions = {
    setDate(context: any, date: string|null) {
        context.commit('setDate', date);
    },
};

const mutations = {
    setDate(state: { selectedDate: string|null }, date: string|null) {
        state.selectedDate = date;
    },
};

export const exercises = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}