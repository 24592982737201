import ApiService from "./api.service";
import {mapGetters} from "vuex";

const PermissionService = {
    computed: {
        ...mapGetters({
            hasMainAccount: 'auth/hasMainAccount',
        }),
    },

    async fetchPermission(guardian = false) {
        return new Promise((resolve, reject) => {
            ApiService.get('permission' + (guardian ? '?guardian=1' : '')).then(({data}: any) => {
                resolve(data);
            }).catch((err: any) => {
                reject(err.message);
            });
        })
    },
}

export {PermissionService};
