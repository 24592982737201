import i18n from 'vue-i18n'

export default {
    default: {
        backgroundColor: '#FFFFFF',
        primaryColor: '#000000',
        secondaryColor: '#91c400',
        contrastTextColor: '#000',
        labels: 'themes.default.labels',
        background: false,
        icons: {
            welcome: 'welcome',
            information: 'content-items',
            exercises: 'exercises',
            messages: 'messages',
            timetable: 'clock',
            notes: 'notepad',
            questionnaires: 'questionnaires',
            team: 'team',
            goals: 'goals',
            activities: 'activities',
            patients: 'team',
            children: 'children',
            help: 'help',
            health: 'health',
        },
    },
    construction: {
        backgroundColor: '#fff',
        primaryBackgroundColor: '#87ddf4',
        primaryColor: '#5184A1',
        secondaryColor: '#87ddf4',
        contrastTextColor: '#000',
        labels: 'themes.construction.labels',
        background: 'assets/themes/construction/background.jpg',
        icons: {
            welcome: 'welcome',
            goals: 'goals',
            activities: 'activities',
            help: 'help',
            timetable: 'clock',

            health: 'assets/themes/space/health.png',
            information: 'assets/themes/construction/content-items.png',
            exercises: 'assets/themes/construction/exercises.png',
            messages: 'assets/themes/construction/message.png',
            notes: 'assets/themes/construction/notepad.png',
            questionnaires: 'assets/themes/construction/questionnaires.png',
            team: 'assets/themes/construction/team.png',
            children: 'children',
            patients: 'assets/themes/construction/team.png',
        },
    },
    space: {
        backgroundColor: '#eee9e1',
        primaryBackgroundColor: '#7eb5dc',
        primaryColor: '#FF6139',
        secondaryColor: '#7eb5dc',
        contrastTextColor: '#000',
        labels: 'themes.space.labels',
        background: 'assets/themes/space/background.png',
        icons: {
            welcome: 'welcome',
            goals: 'goals',
            activities: 'activities',
            help: 'help',
            timetable: 'clock',

            health: 'assets/themes/space/health.png',
            information: 'assets/themes/space/content-items.png',
            exercises: 'assets/themes/space/exercises.png',
            messages: 'assets/themes/space/messages.png',
            notes: 'assets/themes/space/notepad.png',
            questionnaires: 'assets/themes/space/questionnaires.png',
            team: 'assets/themes/space/team.png',
            children: 'children',
            patients: 'assets/themes/space/team.png',
        },
    },
    craft: {
        backgroundColor: '#eee9e1',
        backgroundPrimaryColor: '#eee9e1',
        primaryColor: '#000',
        secondaryColor: '#eee9e1',
        contrastTextColor: '#000',
        labels: 'themes.craft.labels',
        background: 'assets/themes/craft/background.png',
        icons: {
            welcome: 'welcome',
            goals: 'goals',
            activities: 'activities',
            help: 'help',
            timetable: 'clock',

            health: 'assets/themes/space/health.png',
            information: 'assets/themes/craft/content-items.svg',
            exercises: 'assets/themes/craft/exercises.svg',
            messages: 'assets/themes/craft/messages.svg',
            notes: 'assets/themes/craft/notepad.svg',
            questionnaires: 'assets/themes/craft/questionnaires.svg',
            team: 'assets/themes/craft/team.svg',
            children: 'children',
            patients: 'assets/themes/craft/team.svg',
        },
    },
    plant: {
        backgroundColor: '#7fc7e1',
        backgroundPrimaryColor: '#7fc7e1',
        primaryColor: '#73AF34',
        secondaryColor: '#7fc7e1',
        contrastTextColor: '#000',
        labels: 'themes.plant.labels',
        background: 'assets/themes/plant/background.jpg',
        icons: {
            welcome: 'welcome',
            goals: 'goals',
            activities: 'activities',
            help: 'help',
            timetable: 'clock',

            health: 'assets/themes/space/health.png',
            information: 'assets/themes/plant/content-items.png',
            exercises: 'assets/themes/plant/exercises.png',
            messages: 'assets/themes/plant/messages.png',
            notes: 'assets/themes/plant/notepad.png',
            questionnaires: 'assets/themes/plant/questionnaires.png',
            team: 'assets/themes/plant/team.png',
            children: 'children',
            patients: 'assets/themes/plant/team.png',
        },
    },
};
