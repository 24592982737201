import _ from "lodash";
import themes from "@/store/themes";

const state = {
    companyName: 'Telerevalidatie',
    theme: {
        ...themes.default
    },
    settings: {
        theme: localStorage.getItem('settings.theme') ?? 'default',
        locale: localStorage.getItem('settings.locale') ?? 'nl',
        fontSize: localStorage.getItem('settings.font_size') ?? 'medium',
    }
};

const getters = {
    themeColors(state: any) {
        return state.theme;
    },
    fontSize(state: any) {
        return state.settings.fontSize;
    },
    fontSizePixels(state: any) {
        const size = state.settings.fontSize;

        if (size === 'small') {
            return '14px';
        }

        if (size === 'large') {
            return '20px';
        }

        if (size == 'xl') {
            return '22px';
        }

        if (size == 'xxl') {
            return '24px';
        }

        return '16px'
    },
    getLabel: (state: any) => (label: string) => {
        return state.theme.labels + '.' + label;
    },
    getIcon: (state: any) => (icon: string) => {
        return state.theme.icons[icon];
    },
    theme(state: any) {
        return state.settings.theme;
    },
    locale(state: any) {
        return state.settings.locale;
    },
};

const actions = {
    setFontSize(context: { commit: (arg0: string, arg1: string) => void }, size: string) {
        localStorage.setItem('settings.font_size', size);
        context.commit('setFontSize', size);
    },
    setLocale(context: { commit: (arg0: string, arg1: string) => void }, locale: string) {
        localStorage.setItem('settings.locale', locale);
        context.commit('setLocale', locale);
    },
    changeTheme(context: { commit: (arg0: string, arg1: { name: string; theme: any }) => void }, theme: string) {
        if (_.has(themes, theme)) {
            localStorage.setItem('settings.theme', theme);
            context.commit('changeTheme', {
                name: theme,
                theme: _.get(themes, theme),
            });
        }
    }
};

const mutations = {
    setFontSize(state: any, size: string) {
        state.settings.fontSize = size;
    },
    setLocale(state: any, locale: string) {
        state.settings.locale = locale;
    },
    changeTheme(state: any, payload: { name: string; theme: any }) {
        const { name, theme } = payload;

        state.theme = theme;
        state.settings.theme = name;
    }
};

export const app = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
