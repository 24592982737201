import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import { flare } from "@flareapp/flare-client";

flare.addContext('app_name', process.env.VUE_APP_NAME);

if (process.env.NODE_ENV === 'production') {
    flare.light('uakh92kbqWW1BNvcMuEw4hNt6HfdpSfm');
}

import App from "./App.vue";
import router from "./router";
import { store } from "./store";

import { IonicVue, createAnimation } from "@ionic/vue";
import ApiService from "./services/api.service";
import { TokenService } from "./services/token.service";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/general.scss";

const en = require("./lang/en") as {};
const nl = require("./lang/nl") as {};

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem("settings.locale") ?? "nl",
  messages: {
    en,
    nl
  }
});

const app = createApp(App)
  .use(IonicVue, {
    swipeBackEnabled: true,
    backButtonDefaultHref: "/",
    backButtonDefaultTitle: "Terug"
  })
  .use(router)
  .use(i18n)
  .use(store);

router.isReady().then(() => {
  app.mount("#app");
});

ApiService.init(
  localStorage.getItem("BASE_API") ?? process.env.VUE_APP_ROOT_API
);

if (TokenService.getToken()) {
  ApiService.setHeader();
  ApiService.mountRequestInterceptor();
  ApiService.mount401Interceptor();
}
