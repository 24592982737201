import { AuthenticationError, AuthService } from "@/services/auth.service";
import { TokenService } from "@/services/token.service";
import { Child } from "@/interfaces/children";
import ApiService from "@/services/api.service";

const state = {
  authenticating: false,
  accessToken: TokenService.getToken(),
  authenticationErrorCode: 0,
  authenticationError: "",
  refreshTokenPromise: null,
  hasMainAccount: TokenService.hasMainAccount(),
  credentials: {},
  isProfessional: false
};

const getters = {
  authenticationErrorCode: (state: { authenticationErrorCode: any }) => {
    return state.authenticationErrorCode;
  },

  authenticationError: (state: { authenticationError: any }) => {
    return state.authenticationError;
  },

  authenticating: (state: { authenticating: any }) => {
    return state.authenticating;
  },

  authenticated(state: { accessToken: any }): boolean {
    return !!state.accessToken;
  },

  credentials(state: { credentials: {} }): object {
    return state.credentials;
  },

  hasMainAccount(state: { hasMainAccount: boolean }): boolean {
    return state.hasMainAccount;
  },

  isProfessional(state: { isProfessional: boolean }): boolean {
    return state.isProfessional;
  }
};

const actions = {
  async signIn(context: any, signInData: any) {
    context.commit("signInRequest");
    return new Promise((resolve, reject) => {
      AuthService.signIn(signInData)
        .then((res: any) => {
          context.commit("signInSuccess", res);
          resolve(res);
        })
        .catch((err: any) => {
          if (err instanceof AuthenticationError) {
            context.commit("signInError", {
              errorCode: err.errorCode,
              errorMessage: err.message
            });
            reject(err.message);
          }
        });
    });
  },

  async signInWithAccessToken(context: any, accessToken: string) {
    context.commit("signInRequest");
    return new Promise((resolve, reject) => {
      AuthService.signInWithAccessToken(accessToken)
        .then(res => {
          context.commit("signInSuccess", res);
          resolve(res);
        })
        .catch((err: any) => {
          if (err instanceof AuthenticationError) {
            context.commit("signInError", {
              errorCode: err.errorCode,
              errorMessage: err.message
            });
            reject(err.message);
          }
        });
    });
  },

  signOut(context: any) {
    context.commit("signOutRequest");
    return new Promise(resolve => {
      AuthService.signOut();
      resolve(1);
    });
  },

  refreshToken(context: any, state: { refreshTokenPromise: any }) {
    if (!state.refreshTokenPromise) {
      const p = AuthService.refreshToken();
      context.commit("refreshTokenPromise", p);

      p.then(
        (response: any) => {
          context.commit("refreshTokenPromise", null);
          context.commit("loginSuccess", response);
        },
        (error: any) => {
          context.commit("refreshTokenPromise", error);
        }
      );
    }

    return state.refreshTokenPromise;
  },

  setAuthenticatingStatus(context: any, status: any) {
    context.commit("setAuthenticatingStatus", status);
  },

  async switchAccount(context: any, child: Child) {
    const { data } = await ApiService.get(`/patientGuardian/${child.id}`);

    TokenService.switchAccount(data.access_token);
    ApiService.setHeader();
    context.commit("switchToAccount", {
      accessToken: data.access_token,
      hasMainAccount: true
    });
  },

  switchBackToMain(context: any) {
    const token = TokenService.switchBack();
    ApiService.setHeader();
    context.commit("switchToAccount", {
      accessToken: token,
      hasMainAccount: false
    });
  }
};

const mutations = {
  signInRequest(state: {
    authenticating: boolean;
    authenticationError: string;
    authenticationErrorCode: number;
  }) {
    state.authenticating = true;
    state.authenticationError = "";
    state.authenticationErrorCode = 0;
  },

  signInSuccess(
    state: {
      accessToken: any;
      authenticating: boolean;
    },
    accessToken: any
  ) {
    state.accessToken = accessToken;
    state.authenticating = false;
  },

  signInError(
    state: {
      authenticating: boolean;
      authenticationErrorCode: any;
      authenticationError: any;
    },
    { errorCode, errorMessage }: any
  ) {
    state.authenticating = false;
    state.authenticationErrorCode = errorCode;
    state.authenticationError = errorMessage;
  },

  signOutRequest(state: { authenticating: boolean; accessToken: any }) {
    state.authenticating = false;
    state.accessToken = false;
  },

  refreshTokenPromise(state: { refreshTokenPromise: any }, promise: any) {
    state.refreshTokenPromise = promise;
  },

  processSuccess(state: { authenticating: boolean }) {
    state.authenticating = false;
  },

  setAuthenticatingStatus(state: { authenticating: any }, status: any) {
    state.authenticating = status;
  },

  setCredentials(state: { credentials: any }, credentials: any) {
    state.credentials = credentials;
  },

  switchToAccount(
    state: { accessToken: string; hasMainAccount: boolean },
    { accessToken, hasMainAccount }: any
  ) {
    state.accessToken = accessToken;
    state.hasMainAccount = hasMainAccount;
  },

  setProfessional(state: { isProfessional: boolean }) {
    state.isProfessional = true;
  }
};

export const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
