import { Plugins } from "@capacitor/core";
import { AuthService } from "@/services/auth.service";
import { App } from "@capacitor/app";
import { TokenService } from "@/services/token.service";
const { BackgroundTask } = Plugins;

class AccessService {
  constructor() {
    if (localStorage.getItem("lastAccess") !== null) {
      this.checkAccessAndLogout();
    }

    App.addListener("appStateChange", state => {
      if (state.isActive) {
        if (localStorage.getItem("lastAccess") !== null) {
          this.checkAccessAndLogout();
        }
      } else {
        localStorage.setItem("lastAccess", Date.now().toString());
      }
    });

    if (typeof BackgroundTask != "undefined") {
      const taskId = BackgroundTask.beforeExit(async () => {
        localStorage.setItem("lastAccess", Date.now().toString());
        BackgroundTask.finish({ taskId });
      });
    }
  }

  private checkAccessAndLogout(): void {
    const lastAccess = parseFloat(localStorage.getItem("lastAccess") as string);
    const time = localStorage.getItem('EXPIRY_TIME')
        ? lastAccess + 60 * parseInt(localStorage.getItem('EXPIRY_TIME') as string) * 1000
        : lastAccess + 60 * 60 * process.env.VUE_APP_LOGIN_EXPIRY_TIME * 1000;

    if (
      TokenService.getToken() &&
      Date.now() >= time
    ) {
      localStorage.removeItem("lastAccess");
      AuthService.signOut();
      window.location.reload();
    } else {
      localStorage.removeItem("lastAccess");
    }
  }
}

export default AccessService;
