module.exports = {
  close: "Sluiten",
  send: "Versturen",
  respond: "Reageer",
  selectLanguage: "Selecteer taal",
  cancel: "Annuleren",
  update: "Bijwerken",
  date: "Datum",
  apply: "OK",
  startTime: "Start tijd",
  endTime: "Eind tijd",
  back: "Terug",
  edit: "Aanpassen",
  recipient: "ontvanger",
  delete: "Verwijder",
  description: "Beschrijving",
  save: "Opslaan",
  saved: "Opgeslagen",
  saveAndNew: "Opslaan en nieuwe",
  at: "om",
  from: "van",
  yes: "Ja",
  no: "Nee",
  and: "en",
  areYouSure: "Weet u het zeker?",
  addFiles: "Bestanden toevoegen",
  addFile: "Bestand toevoegen",
  environment: {
    name: "Omgeving"
  },
  months: [
    "januari",
    "februari",
    "maart",
    "april",
    "mei",
    "juni",
    "juli",
    "augustus",
    "september",
    "oktober",
    "november",
    "december"
  ],
  errors: {
    somethingWentWrong: "Er ging iets mis. Probeer het later nog eens."
  },
  greetings: {
    morning: "Goedemorgen",
    afternoon: "Goedemiddag",
    evening: "Goedenavond"
  },
  auth: {
    loading: "Aan het laden...",
    description: "Login om Telerevalidatie te gebruiken.",
    version: "Versie: v{version}",
    header: "Login",
    email: "E-mailadres of telefoonnummer",
    password: "Wachtwoord",
    login: "Login",
    failed: "Fout",
    failedText: "Onjuiste inloggevens",
    failedContact: "U kunt niet inloggen",
    failedTextContact: "Als mantelzorger kunt u helaas alleen het webportaal gebruiken en niet de App",
    forgotPasswordQuestion: "Wachtwoord vergeten?",
    forgotHeader: "Wachtwoord vergeten",
    forgotDescription: "Voer uw e-mailadres in om uw wachtwoord te resetten",
    forgotSuccessText:
      "Er is een e-mail om uw wachtwoord te resetten naar u toegestuurd",
    reset: "Reset",
    twoFactorHeader: "2-stappen verificatie",
    twoFactorDescription: "Voer hieronder uw authenticator code in.",
    code: "Code",
    ssoLogin: "Login met SSO"
  },
  dashboard: {
    information: "Informatie",
    dashboard: "Dashboard",
    exercises: "Oefeningen",
    notes: "Schrifje",
    team: "Behandelteam",
    settings: "Instellingen",
    health: "Stappen",
    logout: "Uitloggen",
    timetable: "Tijdindeling",
    messages: "Berichten",
    questionnaires: "Vragenlijsten",
    goals: "Doelen",
    activities: "Activiteiten",
    patients: "Patiënten",
    children: "Kinderen"
  },
  team: {
    title: "Behandelteam",
    sendMessage: "Stuur bericht"
  },
  settings: {
    title: "Instellingen",
    general: "Algemeen",
    selectLocale: "Taal",
    selectFontSize: "Tekst grootte",
    fontSizes: {
      small: "Klein",
      medium: "Gemiddeld",
      large: "Groot",
      xl: "Extra groot",
      xxl: "Heel groot",
    },
    changeTheme: "Verander thema",
    themes: {
      default: "Standaard",
      kids: "Kinder",
      construction: "Bouw",
      space: "Ruimte",
      craft: "Ontwerpen",
      plant: "Tuinieren"
    },
    notifications: "Notificaties",
    homeworkNotification: "Huiswerk herinneringen",
    notificationBadge: "Toon badges op startscherm",
    myAccount: "Mijn account",
    security: "Wachtwoord en beveiling",
    logout: "Uitloggen",
    about: "Over",
    privacy: "Privacy policy",
    health: "Stappen",
    bio: "Zet FaceID/TouchID aan",
  },
  security: {
    description: "Wijzig hier uw wachtwoord",
    passwordOld: "Huidig wachtwoord",
    passwordNew: "Nieuw wachtwoord",
    passwordConfirmation: "Herhaal nieuw wachtwoord",
    changePassword: "Wijzig wachtwoord",
    passwordUpdated: "Uw wachtwoord is succesvol gewijzigd"
  },
  lang: {
    dutch: "Nederlands",
    english: "Engels"
  },
  exercises: {
    title: "Oefeningen",
    complete: "Oefening afgerond",
    amountToDo: "{current} van de {max} gedaan",
    toDo: "Nog te doen",
    none: "Er zijn geen oefeningen beschikbaar voor de geselecteerde dag",
    markAsDone: "Gedaan",
    markForSkip: "Sla deze oefening over",
    previousDay: 'Vorige dag',
    nextDay: 'Volgende dag',
    viewOptions: 'Bekijk opties',
    actions: 'Acties'
  },
  exercise: {
    instructions: "Instructies",
    addNote: "Voeg opmerking toe",
    notePlaceholder: "Voeg hier uw opmerking toe",
    modalStatusTextcomplete:
      "Wanneer u op versturen klikt markeert u de oefening als gedaan met de notitie erbij.",
    modalStatusTextskip:
      "Wanneer u op versturen klikt markeert u de oefening als overgeslagen met de notitie erbij.",
    statusChanged: "De status van deze oefening is bijgewerkt",
    videoProcessing: "Bij deze oefening zit een video. Op dit moment wordt de video nog verwerkt.",
  },
  notes: {
    title: "Schriftje",
    noNotes: "Er zijn geen berichten",
    note: "Schrift",
    newMessage: "Nieuw bericht",
    message: "Bericht",
    private: "Privé",
    other: "Andere schriftjes",
    notifyUsers: "Stuur notificatie naar",
    everyone: "Iedereen",
    showMessages: "Toon berichten van",
    filter: "Filter",
  },
  information: {
    noItems: "Er zijn geen artikelen",
    yourAnswer: "Jouw gegeven antwoord:",
    title: "Informatie",
    complete: "Afgerond",
    answer: "Antwoord",
    answerBtn: "Vul een antwoord in",
    answerPlaceholder: "Voeg hier uw antwoord toe",
    answerSubmitted: "Uw antwoord is verstuurd",
    files: "Bestanden",
    unread: 'Ongelezen',
    read: 'Gelezen',
    markAsUnread: "Markeren als ongelezen",
    unreadMessage: "Dit item is gemarkeerd als ongelezen",
    onlyFavorite: "Toon alleen mijn favorieten",
    markAsFavorite: "Item gemarkeerd als favoriet",
    markAsUnFavorite: "Item verwijderd als favoriet",
    toggleFavorite: "Markeren als favoriet",
    untoggleFavorite: "Verwijderen als favoriet",
    extraPages: "Inhoudsopgave",
    videoProcessing: "Bij dit informatie item zit een video. Op dit moment wordt de video nog verwerkt.",
  },
  messages: {
    title: "Berichten",
    noMessages: "Er zijn geen berichten",
    newMessage: "Nieuw bericht",
    subject: "Onderwerp",
    message: "Bericht",
    sent: "Verstuurd",
    received: "Ontvangen",
    lastMessage: "Laatste bericht",
    validation: {
      recipients: "Selecteer een ontvanger."
    },
    newResponse: "Reactie",
    selectRecipients: "Selecteer ontvangers",
    recipients: {
      private: "Privé",
      recipients: "Ontvangers",
      apply: "OK",
      cancel: "Sluiten",
      notify: "Notificeer"
    },
    other: "Andere berichten",
    willSentTo: "Dit bericht wordt verstuurd naar: {users}",
    videoProcessing: "De video die is bijgevoegd wordt op dit moment nog verwerkt. Een ogenblik geduld alstublieft. Later kunt u deze bekijken en downloaden.",
    private: "Vink dit hokje aan als je wil dat je ouders het bericht wat je nu gaat versturen niet kunnen lezen.",
  },
  goals: {
    title: "Doelen",
    targetDate: "Afronden voor:",
    completedAt: "Afgerond op:",
    newGoal: "Nieuw doel",
    what: "Wat is je doel?",
    when: "Wanneer wil je dit bereikt hebben?",
    how: "Hoe wil je dit bereiken?",
    complete: "Doel afronden",
    goalCreated: "Nieuw doel is aangemaakt",
    goalCompleted: "Doel is afgerond",
    editGoal: "Bewerk doel",
    goalUpdated: "Doel is bijgewerkt",
    completeGoalDescription: "Wil je dit doel afronden?",
    completeShort: "Afronden",
    empty: "Je hebt nog geen doelen aangemaakt."
  },
  schedule: {
    title: "Tijdindeling",
    newActivity: "Nieuwe activiteit",
    editActivity: "Activiteit bewerken",
    manageActivities: "Activiteiten beheren",
    timeSettings: "Tijd instellingen",
    helpText: 'Hou een tijdstip langer ingedrukt in de kalender om een nieuwe activiteit aan te maken.',
  },
  activities: {
    noActivities: "Voeg een nieuwe activiteit toe om te beginnen",
    activities: "Activiteiten",
    activity: "Activiteit",
    minutes: "minuten",
    perHour: "per uur",
    new: "Nieuw",
    edit: "Bewerken",
    category: "Categorie",
    selectActivity: "Selecteer"
  },
  validation: {
    description: "Beschrijving verplicht"
  },
  tests: {
    title: "Vragenlijsten",
    open: "Open",
    finished: "Afgerond",
    nothingOpen: "U heeft geen openstaande vragenlijsten",
    nothingFinished: "U heeft geen afgeronde vragenlijsten",
    next: "Volgende vraag",
    prev: "Vorige vraag",
    previewExisting: "U bekijkt een ingevulde vragenlijst",
    sendNew: "Verstuur vragenlijst",
    typeHere: "Voer hier uw antwoord in",
    selectDate: "Selecteer een datum",
    selectDropdown: "Selecteer een optie",
    validation: {
      required: "Deze vraag is verplicht",
      min: "Het antwoord moet minimaal {number} zijn"
    },
    invalid: "Niet alle vragen zijn juist ingevuld",
    submitted: "Uw ingevulde antwoorden zijn verstuurd"
  },
  patients: {
    title: "Patiënten",
    nothingFound: "Geen resultaten gevonden.",
    otherPatients: "Andere patiënten",
    exercises: "Oefeningen",
    notes: "Schriftje",
    addNote: "Voeg een nieuw bericht toe",
    noExercises: "Er zijn geen oefeningen toegevoegd",
    addExercise: "Oefening toevoegen",
    exerciseName: "Naam van oefening",
    exerciseDescription: "Omschrijving",
    sessionsPerDay: "Sessies per dag",
    sessions: "1 sessie | {count} sessies",
    sets: "Sets",
    noIndication: "Geen indicatie",
    setsOption: "1 set | {count} sets",
    repetitions: "Herhalingen",
    repetitionsOption: "1 herhaling | {count} herhalingen",
    time: "Tijd",
    secondsOption: "{count} seconden",
    minutesOption: "1 minuut | {count} minuten",
    planDirect: "Direct inplannen",
    exerciseCreated: "Oefening is aangemaakt",
    editExercise: "Bewerk oefening",
    exerciseUpdated: "Oefening is bijgewerkt",
    deleteExercise: "Verwijder oefening",
    deleteExerciseDescription:
      "Weet u zeker dat u deze oefening wilt verwijderen?",
    exerciseDeleted: "De oefening is verwijderd"
  },
  themes: {
    default: {
      labels: {
        welcomeText: "Telerevalidatie",
        welcomeTextDescription: "Leuk dat je er weer bent",
        contentItemsText: "Informatie",
        contentItemsTextDescription:
          "Leer meer over je behandeling en hoe je er mee om kunt gaan",
        exercisesText: "Oefeningen",
        exercisesTextDescription:
          "Lekker aan de slag met oefeningen en opdrachten",
        messagesText: "Berichten",
        messagesTextDescription: "Naar je behandelaar",
        notepadText: "Schriftje",
        notepadTextDescription:
          "Stuur berichten, foto's en filmpjes naar al je behandelaren",
        questionnairesText: "Vragenlijsten",
        questionnairesTextDescription:
          "Beantwoord vragen over hoe het met je gaat"
      }
    },
    construction: {
      labels: {
        welcomeText: "Hallo bouwer {name}",
        welcomeTextDescription: "Leuk dat je er weer bent",
        contentItemsText: "Lezen",
        contentItemsTextDescription:
          "Leer meer over je behandeling en hoe je er mee om kunt gaan",
        exercisesText: "Doen",
        exercisesTextDescription:
          "Lekker aan de slag met oefeningen en opdrachten",
        messagesText: "Berichten",
        messagesTextDescription: "Naar je behandelaar",
        notepadText: "Schriftje",
        notepadTextDescription:
          "Stuur berichtjes, foto's en filmpjes naar al je behandelaren",
        questionnairesText: "Vragenlijsten",
        questionnairesTextDescription:
          "Beantwoord vragen over hoe het met je gaat"
      }
    },
    space: {
      labels: {
        welcomeText: "Hallo austronaut {name}",
        welcomeTextDescription: "Leuk dat je er weer bent",
        contentItemsText: "Lezen",
        contentItemsTextDescription:
          "Leer meer over je behandeling en hoe je er mee om kunt gaan",
        exercisesText: "Doen",
        exercisesTextDescription:
          "Lekker aan de slag met oefeningen en opdrachten",
        messagesText: "Berichten",
        messagesTextDescription: "Naar je behandelaar",
        notepadText: "Schriftje",
        notepadTextDescription:
          "Stuur berichtjes, foto's en filmpjes naar al je behandelaren",
        questionnairesText: "Vragenlijsten",
        questionnairesTextDescription:
          "Beantwoord vragen over hoe het met je gaat"
      }
    },
    craft: {
      labels: {
        welcomeText: "Hallo ontwerper {name}",
        welcomeTextDescription: "Leuk dat je er weer bent",
        contentItemsText: "Lezen",
        contentItemsTextDescription:
          "Leer meer over je behandeling en hoe je er mee om kunt gaan",
        exercisesText: "Doen",
        exercisesTextDescription:
          "Lekker aan de slag met oefeningen en opdrachten",
        messagesText: "Berichten",
        messagesTextDescription: "Naar je behandelaar",
        notepadText: "Schriftje",
        notepadTextDescription:
          "Stuur berichtjes, foto's en filmpjes naar al je behandelaren",
        questionnairesText: "Vragenlijsten",
        questionnairesTextDescription:
          "Beantwoord vragen over hoe het met je gaat"
      }
    },
    plant: {
      labels: {
        welcomeText: "Hallo {name}, met de groene vingers",
        welcomeTextDescription: "Leuk dat je er weer bent",
        contentItemsText: "Lezen",
        contentItemsTextDescription:
          "Leer meer over je behandeling en hoe je er mee om kunt gaan",
        exercisesText: "Doen",
        exercisesTextDescription:
          "Lekker aan de slag met oefeningen en opdrachten",
        messagesText: "Berichten",
        messagesTextDescription: "Naar je behandelaar",
        notepadText: "Schriftje",
        notepadTextDescription:
          "Stuur berichtjes, foto's en filmpjes naar al je behandelaren",
        questionnairesText: "Vragenlijsten",
        questionnairesTextDescription:
          "Beantwoord vragen over hoe het met je gaat"
      }
    }
  },
  children: {
    title: "Kinderen",
    description:
      "Hier ziet u een overzicht van al uw kinderen die gebruik maken van de app.",
    login: "Inloggen als",
    loginSuccess: "U bent ingelogd in het account",
    switchBack: "Ga terug naar uw account",
    switchBackSuccess: "U bent terug geswitcht naar uw account"
  },
  noRecipients: "Geen ontvanger(s) geselecteerd",
  health: {
    noSteps: "Geen data van jouw stappen. Kom later terug.",
    stepsTaken: "stappen gezet",
    history: "Stappen",
    steps: "stappen"
  },
  upload: {
    more: {
      title: 'Hoe wil je het bestand toevoegen?',
      camera: 'Fotocamera gebruiken',
      file: 'Bestand selecteren',
      cancel: 'Annuleren',
      video: 'Neem een video op',
    },
  },
  legend: 'Legenda',
  descriptionRequired: 'Omschrijving is verplicht',
  media: {
    title: "Media",
    new: "Nieuwe media item toevoegen"
  },
};
