import ApiService from "./api.service";
import { TokenService } from "./token.service";
import { AxiosRequestConfig } from "axios";
import qs from "qs";

class AuthenticationError extends Error {
  public errorCode: any;
  constructor(errorCode: any, message: string | undefined) {
    super(message);
    this.name = this.constructor.name;
    if (message != null) {
      this.message = message;
    }
    this.errorCode = errorCode;
  }
}

const AuthService = {
  signIn: async function(signInData: any) {
    const requestData: AxiosRequestConfig = {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      url: "/login",
      data: qs.stringify({
        // eslint-disable-next-line @typescript-eslint/camelcase
        grant_type: "password",
        email: signInData.username,
        password: signInData.password,
        token: signInData.token ?? null
      })
    };

    try {
      const response = await ApiService.customRequest(requestData);
      TokenService.saveToken(response.data.access_token);
      TokenService.saveRefreshToken(response.data.refresh_token);
      ApiService.setHeader();

      ApiService.mount401Interceptor();

      return response.data.access_token;
    } catch (error) {
      this.catchError(error);
    }
  },

  async signInWithAccessToken(accessToken: string) {
    TokenService.saveToken(accessToken);
    ApiService.setHeader();
    ApiService.mount401Interceptor();
    return accessToken;
  },

  async refreshToken() {
    const refreshToken = TokenService.getRefreshToken();

    const requestData: AxiosRequestConfig = {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Basic " +
          btoa(
            process.env.VUE_APP_CLIENT_ID +
              ":" +
              process.env.VUE_APP_CLIENT_SECRET
          )
      },
      url: "/oauth/token",
      data: qs.stringify({
        // eslint-disable-next-line @typescript-eslint/camelcase
        grant_type: "refresh_token",
        refreshToken: refreshToken
      })
    };

    try {
      const response = await ApiService.customRequest(requestData);

      TokenService.saveToken(response.data.access_token);
      TokenService.saveRefreshToken(response.data.refresh_token);
      ApiService.setHeader();

      return response.data.access_token;
    } catch (error) {
      throw new AuthenticationError(
        error.response.status,
        error.response.data.error_description
      );
    }
  },

  signOut() {
    TokenService.removeToken();
    TokenService.removeRefreshToken();
    ApiService.removeHeader();
    ApiService.unmount401Interceptor();
  },

  catchError: function(error: any) {
    let status;
    let description;

    if (error.response === undefined) {
      status = error.message;
      description = error.message;
    } else {
      status = error.response.status;
      description = error.response.data.message;
    }

    throw new AuthenticationError(status, description);
  }
};

export { AuthService, AuthenticationError };
