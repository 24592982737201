import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonRouterOutlet = _resolveComponent("IonRouterOutlet")!
  const _component_IonApp = _resolveComponent("IonApp")!

  return (_openBlock(), _createBlock(_component_IonApp, {
    id: "app",
    style: _normalizeStyle(_ctx.cssVars),
    class: _normalizeClass({ 'is-iphone': _ctx.isPlatform('iphone') })
  }, {
    default: _withCtx(() => [
      _createVNode(_component_IonRouterOutlet, {
        ref: "routerOutlet",
        animated: "true",
        id: "main-content"
      }, null, 512)
    ]),
    _: 1
  }, 8, ["style", "class"]))
}