import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import {store} from '@/store';

const routes: Array<RouteRecordRaw> = [
    {
        name: 'dashboard',
        path: '',
        component: () => import('../views/Dashboard.vue')
    },
    {
        name: 'faker',
        path: '/faker',
        component: () => import('../views/Faker.vue')
    },
    {
        name: 'settings',
        path: '/settings',
        component: () => import('../views/Settings.vue')
    },
    {
        name: 'security',
        path: '/settings/security',
        component: () => import('../views/settings/Security.vue')
    },
    {
        name: 'privacy',
        path: '/settings/privacy',
        component: () => import('../views/settings/Privacy.vue')
    },
    {
        name: 'health',
        path: '/health',
        component: () => import('../views/health/Health.vue'),
    },
    {
        path: '/exercises',
        component: () => import('../views/exercises/Exercises.vue')
    },
    {
        path: '/exercises',
        component: () => import('../views/exercises/Exercises.vue')
    },
    {
        path: '/exercises/:id',
        component: () => import('../views/exercises/Exercise.vue')
    },
    {
        path: '/notes',
        name: 'notes',
        component: () => import('../views/notes/Notes.vue')
    },
    {
        path: '/notes/therapist',
        component: () => import('../views/notes/Notes.vue')
    },
    {
        path: '/notes/:id',
        component: () => import('../views/notes/Note.vue')
    },
    {
        path: '/messages',
        name: 'messages',
        component: () => import('../views/messages/Messages.vue')
    },
    {
        path: '/messages/message/:id',
        component: () => import('../views/messages/Messages.vue')
    },
    {
        path: '/messages/:id',
        component: () => import('../views/messages/Message.vue')
    },
    {
        path: '/team',
        component: () => import('../views/team/Team.vue')
    },
    {
        path: '/information',
        component: () => import('../views/information/Information.vue')
    },
    {
        path: '/information/:id',
        component: () => import('../views/information/Item.vue')
    },
    {
        path: '/information/:id/:child',
        component: () => import('../views/information/Item.vue')
    },
    {
        path: '/goals',
        component: () => import('../views/goals/Goals.vue')
    },
    {
        path: '/schedule',
        component: () => import('../views/schedule/Schedule.vue')
    },
    {
        path: '/patients',
        component: () => import('../views/patients/Patients.vue')
    },
    {
        path: '/patients/:id',
        component: () => import('../views/patients/Patient.vue')
    },
    {
        path: '/media',
        component: () => import('../views/media/Index.vue')
    },
    {
        path: '/children',
        component: () => import('../views/Children.vue')
    },
    {
        path: '/auth/2fa',
        name: 'two-factor',
        component: () => import('../views/auth/TwoFactor.vue'),
        meta: {
            private: false,
        },
    },
    {
        path: '/auth/forgot',
        name: 'forgot',
        component: () => import('../views/auth/ForgotPassword.vue'),
        meta: {
            private: false,
        },
    },
    {
        path: '/tests',
        component: () => import('../views/tests/Tests.vue')
    },
    {
        path: '/tests/:id',
        component: () => import('../views/tests/Test.vue')
    },

  {
    path: '/auth/2fa',
    name: 'two-factor',
    component: () => import('../views/auth/TwoFactor.vue'),
    meta: {
      private: false,
    },
  },
  {
    path: '/auth/forgot',
    name: 'forgot',
    component: () => import('../views/auth/ForgotPassword.vue'),
    meta: {
      private: false,
    },
  },
    {
        path: '/auth/login',
        name: 'login',
        component: () => import('../views/auth/Login.vue'),
        meta: {
            private: false,
        },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta?.private !== false && !store.getters['auth/authenticated']) {
        next('/auth/login');
    } else if (to.meta?.private === false && store.getters['auth/authenticated']) {
        next('/');
    } else {
        next();
    }
});

export default router
