import {PermissionService} from "@/services/permission.service";
import _ from 'lodash';
import {Badge} from "@ionic-native/badge";
import moment from "moment";

const state = {
    permission: {},
};

const getters = {
    getPermission: (state: any) => (key: any) => {
        if (key === 'user.first_name') {
            const firstName = _.get(state.permission, 'user.full_name', null);
            if (firstName) {
                return _.get(firstName.split(' '), '0', null);
            }
        } else if (key === 'totalBadges') {
            return _.sum(_.values(state.permission.badgeStatistics));
        } else if (key === 'user.profile_picture') {
            return _.get(state.permission, key, '/assets/user.svg') ?? '/assets/user.svg'
        } else if (key === 'isAdult') {
            return moment().diff(_.get(state, 'permission.user.birth_date', moment()), 'years') >= 18;
        } else if (key === 'user.isChild') {
            return moment().diff(_.get(state, 'permission.user.birth_date', moment()), 'years') < 18;
        }

        return _.get(state.permission, key, null);
    },

    isChild: (state: any) => {
        return _.get(state.permission, 'user.isChild', null)
    },
};

const actions = {
    async fetchPermission(context: any, guardian: boolean) {
        return new Promise((resolve, reject) => {
            PermissionService.fetchPermission(guardian).then((res: any) => {
                context.commit("permissionSuccess", res);
                resolve(res);
            }).catch((err: any) => {
                context.commit("permissionSuccess", {});
                reject(err.message);
            });
        });
    },
};

const mutations = {
    permissionSuccess(state: {
        permission: object;
    }, permission: object) {
        state.permission = permission;

        if (_.get(state.permission, 'user.show_badges', false)) {
            Badge.clear();
        }
    },
};

export const permission = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
