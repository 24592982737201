
import { IonApp, IonRouterOutlet, isPlatform } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { mapActions, mapGetters } from "vuex";
import { Style, StatusBar } from "@capacitor/status-bar";
import { useI18n } from "vue-i18n";
import ApiService from "@/services/api.service";
import { useRouter } from "vue-router";
import AccessService from "@/services/access.service";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet
  },

  setup() {
    const { t } = useI18n();
    StatusBar.setStyle({
      style: Style.Light
    });

    const router = useRouter();
    const routerOutlet = ref(IonRouterOutlet);

    return {
      router,
      isPlatform,
      t,
      routerOutlet
    };
  },

  computed: {
    cssVars(): unknown {
      return {
        "--ion-alt-color-contrast": this.themeColors.contrastTextColor,
        "--ion-color-primary": this.themeColors.primaryColor,
        "--ion-color-secondary": this.themeColors.secondaryColor
      };
    },

    ...mapGetters({
      fontSizePx: "app/fontSizePixels",
      theme: "app/theme",
      themeColors: "app/themeColors"
    })
  },

  watch: {
    fontSizePx() {
      this.updateFont();
    }
  },

  methods: {
    ...mapActions({
      changeTheme: "app/changeTheme"
    }),

    ...mapActions("auth", ["signOut"]),

    updateFont() {
      const html = document.querySelector("html");

      if (html) {
        html.style.setProperty("--font-size", this.fontSizePx);
      }
    }
  },

  created() {
    ApiService.mountErrors(this.t("errors.somethingWentWrong"));
    this.updateFont();
    this.changeTheme(this.theme);
    new AccessService();
  }
});
